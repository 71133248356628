import React from "react";
import g_next_door from "../pages/g_next_door.png"
import dominatrix from "../pages/dominatrix.png"
import findom from "../pages/findom.png"

function Mistresses({ handleOptionClick, descriptions = true }) {
  const button_style = 'text-white text-lg font-bold border border-gray-500  rounded-none shadow-white shadow-sm bg-slate-800 ease-in-out hover:bg-slate-700 hover:shadow-none duration-300'
  const image_style = 'w-64 h-64 border border-gray-500 shadow-white  shadow-sm'
  const container_style = "flex flex-col space-y-6 "

  return (
    <div className="flex md:flex-row flex-col  md:space-x-6 mx-auto md:space-y-0 space-y-8 pb-32 md:pb-none">
      <div className={container_style} onClick={() => handleOptionClick(0)}>
        <img className={image_style} src={g_next_door}></img>
        <button className={button_style}>
          {descriptions ? "Mary" : "Hear a sample of Mary"}
        </button>

        {descriptions ? (<span className="text-slate-300 text-center text-md font-regular" style={{ maxWidth: '16rem' }}>
          Mary can be a bit shy, but also quite passionate.
        </span>) : (<span />)}
      </div>
      <div className={container_style} onClick={() => handleOptionClick(1)}>
        <img className={image_style} src={dominatrix}></img>

        <button className={button_style}>
          {descriptions ? "Alexa" : "Hear a sample of Alexa"}        </button>

        {descriptions ? (<span className="text-slate-300 text-center text-md font-regular" style={{ maxWidth: '16rem' }}>
          Alexa is a great companion but can be quite dominating at times.
        </span>) : (<span />)}
      </div>
      <div className={container_style} onClick={() => handleOptionClick(2)}>
        <img className={image_style} src={findom}></img>

        <button className={button_style}>
          {descriptions ? "Rachel" : "Hear a sample of Rachel"}        </button>

        {descriptions ? (<span className="text-slate-300 text-center text-md font-regular" style={{ maxWidth: '16rem' }}>
          Rachel demands attention and has an expensive taste.
        </span>) : (<span />)}
      </div>
    </div>
  );
}

export default Mistresses;
