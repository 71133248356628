import React, { useState, useEffect } from "react";
import axios from "axios";
import "./css/home.css";
import logo from "../assets/img/logo.png";
import PhoneInput from "react-phone-input-2";
import { AiOutlineLoading } from "react-icons/ai";
import { track } from "@vercel/analytics";
import { useNavigate } from "react-router-dom"; // Add this import
import { DeepChat } from "deep-chat-react";
function Home() {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [showOtpInput, setShowOtpInput] = useState(false);
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState(null); // 'signup' or 'login'
  const [message, setMessage] = useState("");
  const navigate = useNavigate(); // Add this line
  function createEmojiRain(emoji) {
    const canvas = document.getElementById("emoji-canvas");
    console.log("canvas", canvas);
    const ctx = canvas.getContext("2d");
    const emojis = [];
    const numEmojis = 5;

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    for (let i = 0; i < numEmojis; i++) {
      const emojiObj = {
        emoji: emoji,
        x: Math.random() * canvas.width,
        y: Math.random() * canvas.height,
        speed: Math.random() * 3 + 2,
        size: Math.random() * 2 + 1,
        angle: Math.random() * 360,
      };
      emojis.push(emojiObj);
    }

    function drawEmoji(emojiObj) {
      ctx.font = `${emojiObj.size}em Arial`;
      ctx.save();
      ctx.translate(emojiObj.x, emojiObj.y);
      ctx.rotate((emojiObj.angle * Math.PI) / 180);
      ctx.fillText(emojiObj.emoji, 0, 0);
      ctx.restore();
    }

    function update() {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      emojis.forEach((emojiObj) => {
        emojiObj.y += emojiObj.speed;
        if (emojiObj.y > canvas.height) {
          emojiObj.y = -50;
          emojiObj.x = Math.random() * canvas.width;
        }
        drawEmoji(emojiObj);
      });
      requestAnimationFrame(update);
    }

    update();
  }
  const [isValidToken, setIsValidToken] = useState(false);
  const history = useNavigate();
  useEffect(() => {
    const verifyTokenAndGetCredits = async () => {
      const token = localStorage.getItem("auth");
      console.log("Token", token);
      if (!token) {
        history("/");
        return;
      }

      try {
        // await axios.get("/api/verifyToken", {
        //   headers: { authorization: token },
        // });
        // console.log("Token is valid");
        setIsValidToken(true);
      } catch (error) {
        localStorage.removeItem("auth");
        history("/");
      }
    };

    verifyTokenAndGetCredits();
  }, [history]);
  useEffect(() => {
    createEmojiRain("❤️");
  }, []);

  const openModal = (actionType) => {
    track(actionType + "Modal");
    setShowModal(true);
    setAction(actionType);
  };

  const closeModal = () => {
    setShowModal(false);
    setPhoneNumber("");
    setOtp("");
    setLoading(false);
    setShowOtpInput(false);
    setError("");
    setMessage("");
    setAction(null);
  };

  const handlePhoneSubmit = async () => {
    setError("");
    setMessage("");
    setLoading(true);

    try {
      if (phoneNumber < 18) {
        setError("You must be 18 or older to use this service.");
      } else {
        localStorage.setItem("auth", "allowed");
        closeModal();
      }
    } catch (err) {
      setError("Failed to send SMS. Please try again.");
      track("OTPFailed");
    }
    setLoading(false);
  };

  const handleOtpSubmit = async () => {
    setError("");
    setMessage("");
    setLoading(true);
    try {
      const formattedPhoneNumber = phoneNumber.replace(/-/g, "");
      const response = await axios.post("/api/utilities/verify", {
        phoneNumber: formattedPhoneNumber,
        otp,
      });
      localStorage.setItem("auth", response.data.token);
      setMessage("Verification successful!");
      setShowOtpInput(false);
      window.location.href = "/home";
    } catch (err) {
      setError("Invalid OTP. Please try again.");
    }
    setLoading(false);
  };

  let audio;
  const handleOptionClick = (optionId) => {
    try {
      if (audio) {
        audio.pause();
        audio.currentTime = 0;
      }

      switch (optionId) {
        case 0:
          audio = new Audio("./mary.mp3");
          audio.load();
          break;
        case 1:
          audio = new Audio("./alana.mp3");
          audio.load();
          break;
        case 2:
          audio = new Audio("./rachel.mp3");
          audio.load();
          break;
        default:
          console.log("Invalid option");
          return;
      }
      track("AudioPlay");
      audio.play();
    } catch (err) {
      console.error("Failed to play audio:", err);
    }
  };

  return (
    <div
      className="flex justify-center items-center flex-col"
      style={{ minHeight: "100vh" }}
    >
      <canvas
        style={{
          position: window.innerWidth <= 768 ? "fixed" : "absolute",
          zIndex: 9999,
          pointerEvents: "none",
        }}
        id="emoji-canvas"
      ></canvas>

      <img
        id="1000446871_cpa_testing"
        src={
          "https://ads.trafficjunky.net/ct?a=1000446871&member_id=1006200901&cb=" +
          Math.random().toString() +
          "&cti=" +
          Math.random().toString() +
          "&ctv=0&ctd=website'"
        }
        width="1"
        height="1"
        border="0"
      />
      <img src={logo} className="mt-32" alt="Logo" style={{ width: "20rem" }} />
      <div className="text-center">
        <p className="text-xl text-slate-400 font-regular mt-4">
          AI voice companionship. Try it free today.
        </p>
        <p className="text-md text-slate-400 font-regular  mb-14 px-2 italic">
          A completely interactive AI phone call, with free credits on sign up.
        </p>
      </div>
      <button
        onClick={() => {
          !isValidToken ? openModal("signup") : navigate("/text");
        }}
        className="text-white font-bold bg-green-500 hover:bg-green-700 rounded-lg px-10 py-4 mx-4 transition duration-300 ease-in-out transform hover:scale-110"
        style={{
          minWidth: "150px",
          minHeight: "75px",
          fontSize: "6.125rem",
          lineHeight: "6.75rem",
        }}
      >
        TEXT
      </button>
      <div className="flex justify-center mt-8">
        <button
          onClick={() => {
            !isValidToken ? openModal("signup") : navigate("/home");
          }}
          className="text-white font-bold bg-blue-500 hover:bg-blue-700 rounded-lg px-10 py-4 mx-4 transition duration-300 ease-in-out transform hover:scale-110"
          style={{
            minWidth: "150px",
            minHeight: "75px",
            fontSize: "6.125rem",
            lineHeight: "6.75rem",
          }}
        >
          CALL
        </button>
      </div>
      {showModal && (
        <div className="w-full h-screen fixed flex top-0 left-0 bg-gray-800 bg-opacity-80">
          <div
            className="mx-auto my-auto rounded-sm flex flex-col bg-white md:w-1/4 md:h-1/3 w-11/12 h-1/2 backdrop-filter backdrop-blur-lg bg-opacity-20 "
            onClick={closeModal}
          >
            <div
              className="flex flex-col my-auto mx-auto w-full px-4"
              onClick={(e) => e.stopPropagation()}
            >
              <h2 className="font-serif text-white absolute top-2 left-4">
                {action === "signup" ? "Verify Your Age" : "Login"}
              </h2>
              {!loading && (
                <div className="flex flex-col  w-full ">
                  {showOtpInput ? (
                    <>
                      <input
                        className="w-full"
                        type="number"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter OTP"
                        maxLength="4"
                      />
                      <button
                        className="mt-8 w-full bg-gray-900 shadow-white shadow-sm hover:shadow-none duration-300"
                        onClick={handleOtpSubmit}
                        disabled={loading}
                      >
                        Verify OTP
                      </button>
                    </>
                  ) : (
                    <>
                      {/* <PhoneInput
                        className=""
                        country="us"
                        value={phoneNumber}
                        onChange={(value, country, e, formattedValue) =>
                          setPhoneNumber(formattedValue)
                        }
                        inputProps={{
                          required: true,
                        }}
                        inputStyle={{ width: "100%" }}
                      /> */}
                      <input
                        className="w-full"
                        type="number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        placeholder="Enter your age."
                        maxLength="2"
                      />
                      <button
                        className="mt-4 w-full bg-gray-900 shadow-white shadow-sm hover:shadow-none duration-200 "
                        onClick={handlePhoneSubmit}
                        disabled={loading}
                      >
                        Verify 
                      </button>
                    </>
                  )}
                </div>
              )}
              {loading && (
                <div className="flex w-full">
                  {" "}
                  <AiOutlineLoading className="animate-spin mx-auto my-auto text-8xl duration-1000" />{" "}
                </div>
              )}
              {error && <div className="error-message">{error}</div>}
              {message && <div className="success-message">{message}</div>}
              <button
                className=" bg-gray-900 shadow-red-300 shadow-sm hover:shadow-none duration-300 opacity-80 mt-16 w-1/2"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {/* 
      <div
        className="flex no-wrap "
        style={{ position: "fixed", top: "2rem", right: "2rem" }}
      >
        <button
          onClick={() => openModal("signup")}
          className={`flex justify-center items-center text-lg text-white font-semibold bg-blue-500 hover:bg-blue-600 ease-in-out round shadow-sm shadow-white mr-6 w-30`}
        >
          Sign Up
        </button>
        <button
          onClick={() => openModal("login")}
          className={`flex justify-center items-center text-lg text-white font-semibold bg-slate-800 hover:bg-slate-700 ease-in-out round shadow-sm shadow-white w-30`}
        >
          Login
        </button>
      </div>

      <Mistresses descriptions={false} handleOptionClick={handleOptionClick} />

      {showModal && (
        <div className="w-full h-screen fixed flex top-0 left-0 bg-gray-800 bg-opacity-80">
          <div
            className="mx-auto my-auto rounded-sm flex flex-col bg-white md:w-1/4 md:h-1/3 w-11/12 h-1/2 backdrop-filter backdrop-blur-lg bg-opacity-20 "
            onClick={closeModal}
          >
            <div
              className="flex flex-col my-auto mx-auto w-full px-4"
              onClick={(e) => e.stopPropagation()}
            >
              <h2 className="font-serif text-white absolute top-2 left-4">
                {action === "signup" ? "Sign Up" : "Login"}
              </h2>
              {!loading && (
                <div className="flex flex-col  w-full ">
                  {showOtpInput ? (
                    <>
                      <input
                        className="w-full"
                        type="number"
                        value={otp}
                        onChange={(e) => setOtp(e.target.value)}
                        placeholder="Enter OTP"
                        maxLength="4"
                      />
                      <button
                        className="mt-8 w-full bg-gray-900 shadow-white shadow-sm hover:shadow-none duration-300"
                        onClick={handleOtpSubmit}
                        disabled={loading}
                      >
                        Verify OTP
                      </button>
                    </>
                  ) : (
                    <>
                      <PhoneInput
                        className=""
                        country="us"
                        value={phoneNumber}
                        onChange={(value, country, e, formattedValue) =>
                          setPhoneNumber(formattedValue)
                        }
                        inputProps={{
                          required: true,
                        }}
                        inputStyle={{ width: "100%" }}
                      />
                      <button
                        className="mt-4 w-full bg-gray-900 shadow-white shadow-sm hover:shadow-none duration-200 "
                        onClick={handlePhoneSubmit}
                        disabled={loading}
                      >
                        {action === "signup"
                          ? "Send OTP to Sign Up"
                          : "Send OTP to Login"}
                      </button>
                    </>
                  )}
                </div>
              )}
              {loading && (
                <div className="flex w-full">
                  {" "}
                  <AiOutlineLoading className="animate-spin mx-auto my-auto text-8xl duration-1000" />{" "}
                </div>
              )}
              {error && <div className="error-message">{error}</div>}
              {message && <div className="success-message">{message}</div>}
              <button
                className=" bg-gray-900 shadow-red-300 shadow-sm hover:shadow-none duration-300 opacity-80 mt-16 w-1/2"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      <div
        onClick={() => {
          window.location = "/about";
        }}
        className=" text-slate-400 italic text-center mb-20 underline px-2"
      >
        Your information is always encrypted, and never sold. <br></br> Your
        privacy is of the utmost importance to us. <br></br> Learn more about
        who we are and how we keep your information safe by clicking here.
      </div> */}
    </div>
  );
}

export default Home;
