import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";

import Chat from "./pages/chat";
import LandingPage from "./pages/landingPage";
import { useEffect } from "react";
import verifyToken from "./utilityFunctions/verifyToken";
import Payment from "./pages/billing/Payment";
import AboutPage from "./pages/aboutPage";
import ConversationPage from "./pages/ConversationPage";
import HomePage from "./v1/home";

function App() {
  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      console.log("Order placed! You will receive an email confirmation.");
    }

    if (query.get("canceled")) {
      console.log(
        "Order canceled -- continue to shop around and checkout when you're ready."
      );
    }
  }, []);
  // useEffect(() => {
  //   const hasRun = sessionStorage.getItem("hasRunSmsEffect");
  //   if (!hasRun) {
  //     console.log("heeeey");
  //     window.open("sms:+16692014079&body=heeeey");
  //     sessionStorage.setItem("hasRunSmsEffect", "true");
  //   }

  //   const handleBeforeUnload = () => {
  //     sessionStorage.removeItem("hasRunSmsEffect");
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, []);

  // useEffect(() => {
  //   verifyToken().then((resp) => {
  //     if (!resp) {
  //       localStorage.clear();
  //       window.location.href = "/";
  //     }
  //   });
  // }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pay" element={<Payment />} />
        <Route path="/text" element={<Chat />} />
        <Route path="/home" element={<LandingPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/chat" element={<ConversationPage />} />
        <Route path="*" element={<Home />} />

        <Route path="/v1/home" element={<HomePage />} />
      </Routes>
    </Router>
  );
}

export default App;
