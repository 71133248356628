import React, { useEffect, useState } from "react";
import axios from "axios";
import { redirect, useNavigate } from "react-router-dom";
import { jwtDecode as jwt_decode } from "jwt-decode";
import "./css/landingPage.css"; // Ensure your CSS file path is correct
import Mistresses from "../components/mistresses";
import Payment from "./billing/Payment";
import { track } from "@vercel/analytics";

const mommyMap = {
  0: "Mary",
  1: "Alexa",
  2: "Rachel",
};

function LandingPage() {
  const [isValidToken, setIsValidToken] = useState(false);
  const [credits, setCredits] = useState(null);
  const [hasPaymentMethod, setHasPaymentMethod] = useState(null);
  const [showCallModal, setShowCallModal] = useState(false);
  const [userPhoneNumber, setUserPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null); // 0 for Companion, 1 for Mistress, 2 for Findom
  const history = useNavigate();
  const navigate = useNavigate();
  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [showPurchaseModal, setShowPurchaseModal] = useState(false);
  const [selectedAmount, setSelectedAmount] = useState({
    balance: 200 / 6,
    amount: 6.99,
  });

  // Modified charge function to show the modal
  const charge = () => {
    setShowPurchaseModal(true);
  };

  // New function to handle purchase confirmation
  const confirmPurchase = async () => {
    const token = localStorage.getItem("auth");

    if (purchaseLoading) {
      return;
    }

    try {
      setPurchaseLoading(true);
      const { url } = (
        await axios.post("/api/billing/charge", selectedAmount, {
          headers: { authorization: token },
        })
      ).data;

      setCredits(credits + selectedAmount.balance);
      // Handle successful purchase here
    } catch (error) {
      // Handle error here
    } finally {
      setPurchaseLoading(false);
    }

    // Close the modal after purchase
    setShowPurchaseModal(false);
  };

  useEffect(() => {
    const verifyTokenAndGetCredits = async () => {
      const token = localStorage.getItem("auth");
      // if (!token) {
      //     history('/');
      //     return;
      // }
      setCredits(19);
      setIsValidToken(true);
      setHasPaymentMethod(true);
      return;
      try {
        await axios.get("/api/verifyToken", {
          headers: { authorization: token },
        });

        // const creditsResponse = await axios.get('/api/billing/credits_remaining', {
        //     headers: { 'authorization': token }
        // });

        //
      } catch (error) {
        localStorage.removeItem("auth");
        history("/");
      }
    };

    verifyTokenAndGetCredits();
  }, [history]);

  const handleOptionClick = (optionId) => {
    const token = localStorage.getItem("auth");
    if (token) {
      const decoded = jwt_decode(token);
      setUserPhoneNumber(decoded.phoneNumber.replace(/-/g, ""));
    }
    setSelectedOption(optionId);
    setShowCallModal(true);
  };

  const createCheckout = async () => {
    const token = localStorage.getItem("auth");

    try {
      const { url } = (
        await axios.post(
          "/api/billing/connect",
          {},
          { headers: { authorization: token } }
        )
      ).data;

      if (url) {
        window.location.href = url;
      }
      // Handle successful call initiation here
    } catch (error) {
      // Handle error here
    }
  };

  // const charge = async (amount) => {
  //     const token = localStorage.getItem('auth');

  //     try {
  //         const { url } = (await axios.post('/api/billing/charge', {
  //             amount,
  //         }, { headers: { 'authorization': token } })).data;

  //         setCredits(credits + amount);
  //         // Handle successful call initiation here
  //     } catch (error) {
  //         // Handle error here
  //     }
  // };

  const disconnect = async () => {
    const token = localStorage.getItem("auth");

    try {
      const { url } = (
        await axios.get("/api/billing/disconnect", {
          headers: { authorization: token },
        })
      ).data;
      setHasPaymentMethod(false);
    } catch (error) {
      // Handle error here
    }
  };

  const logout = async () => {
    localStorage.removeItem("auth");
    navigate("/");
  };

  const handleCall = async () => {
    track("CallPlaced");
    setLoading(true);
    const token = localStorage.getItem("auth");

    try {
      await axios.post(
        "/api/call/call",
        { phone_number: userPhoneNumber, mistress_id: selectedOption },
        { headers: { authorization: token } }
      );
      // Handle successful call initiation here
    } catch (error) {
      // Handle error here
    }
    setLoading(false);
    setShowCallModal(false);
  };

  const clearMemory = async () => {
    track("MemoryCleared");
    setLoading(true);
    const token = localStorage.getItem("auth");

    try {
      await axios.post(
        "/api/memories",
        { mistress_id: selectedOption },
        { headers: { authorization: token } }
      );
      // Handle successful call initiation here
    } catch (error) {
      // Handle error here
    }
    setLoading(false);
    setShowCallModal(false);
  };

  if (!isValidToken || credits == null) {
    return <div className="loading">Loading...</div>;
  }
  const purchaseBlock =
    "w-full h-full flex flex-col bg-gray-900 border border-white border-solid";
  return (
    <div className=" h-screen relative flex overflow-y-scroll">
      <div className="flex flex-col my-auto mx-auto space-y-8 mt-20">
        <h1 className="text-4xl mx-auto text-white font-semibold px-3 py-2 cursor-pointer hover:shadow-none duration-1000">
          Choose Your Experience
        </h1>

        <div className="flex justify-center items-center md:flex-wrap md:flex-row flex-col">
          <p className="text-slate-400 font-regular text-xl mr-3">
            💎
            <span className="text-white font-bold text-xl ml-1 mr-2">
              {Math.round(credits * 6)}
            </span>
            credits left
          </p>
          <button
            onClick={() => {
              window.location = "/pay";
            }}
            className="my-4 flex justify-center items-center text-white font-semibold bg-slate-800 hover:bg-slate-700 ease-in-out round shadow-sm shadow-white mx-2"
          >
            <p className="mr-1">One Time Purchase</p>
          </button>
          {hasPaymentMethod ? (
            <button
              onClick={() => {}}
              className="flex justify-center items-center text-white font-semibold bg-slate-800 hover:bg-slate-700 ease-in-out round shadow-sm shadow-white ml-4"
            >
              ⏳<p className="mr-1 ml-2">Add credits</p>
            </button>
          ) : (
            <button
              onClick={createCheckout}
              className="flex justify-center items-center text-white font-semibold bg-slate-800 hover:bg-slate-700 ease-in-out round shadow-sm shadow-yellow-500"
            >
              <svg
                className="fill-white w-5 mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
              >
                <path d="M3.49709 8.06489L4.78355 18.9998H19.2266L20.513 8.06489L16.5032 10.7381L12.0051 4.44076L7.50694 10.7381L3.49709 8.06489ZM2.80577 5.20031L7.00505 7.99983L11.1913 2.13905C11.5123 1.68964 12.1369 1.58555 12.5863 1.90656C12.6761 1.9707 12.7546 2.04926 12.8188 2.13905L17.0051 7.99983L21.2043 5.20031C21.6639 4.89395 22.2847 5.01813 22.5911 5.47766C22.7228 5.67527 22.7799 5.91332 22.7522 6.1492L21.109 20.1167C21.0497 20.6203 20.6229 20.9998 20.1158 20.9998H3.8943C3.38722 20.9998 2.9604 20.6203 2.90115 20.1167L1.25792 6.1492C1.19339 5.6007 1.58573 5.10374 2.13423 5.03921C2.37011 5.01146 2.60816 5.06856 2.80577 5.20031ZM12.0051 14.9998C10.9005 14.9998 10.0051 14.1044 10.0051 12.9998C10.0051 11.8953 10.9005 10.9998 12.0051 10.9998C13.1096 10.9998 14.0051 11.8953 14.0051 12.9998C14.0051 14.1044 13.1096 14.9998 12.0051 14.9998Z"></path>
              </svg>
              <p className="mr-1">Add credit card</p>
            </button>
          )}
        </div>

        <Mistresses handleOptionClick={handleOptionClick} />

        <div className="flex justify-center flex-wrap pb-20">
          <button
            onClick={logout}
            className="flex justify-center items-center text-white font-semibold bg-slate-800 hover:bg-slate-700 ease-in-out round shadow-sm shadow-white ml-4"
          >
            👋
            <p className="text-white-400 ml-2">Logout</p>
          </button>
          {hasPaymentMethod ? (
            <button
              onClick={disconnect}
              className="flex justify-center items-center text-white font-semibold bg-slate-800 hover:bg-slate-700 ease-in-out round shadow-sm shadow-white ml-4"
            >
              ❌<p className="text-rose-400 ml-2">Remove credit card</p>
            </button>
          ) : (
            <div />
          )}
          {showPurchaseModal && (
            <div className="fixed w-full h-screen flex top-0 left-0">
              <div className="mx-auto my-auto flex flex-col bg-slate-900 rounded-sm px-4 py-4 md:w-1/3">
                <h2 className="text-center text-white text-lg font-bold mb-8 mt-3">
                  Add credits
                </h2>

                {/* Replace these with your actual amounts and styling */}
                <div className="flex justify-center flex-wrap gap-4">
                  <button
                    onClick={() =>
                      setSelectedAmount({ amount: 3.99, balance: 50 / 6 })
                    }
                    className={`${
                      selectedAmount.amount === 3.99
                        ? "border-white border bg-slate-600 shadow-md shadow-white"
                        : "bg-slate-800"
                    } flex justify-center items-center text-white font-semibold hover:bg-slate-700 ease-in-out round shadow-sm shadow-white w-40`}
                  >
                    <p className="mr-2">💎 50</p>

                    <p className="text-slate-400">$3.99</p>
                  </button>

                  <div className="relative">
                    <p
                      className="absolute text-center bg-yellow-500 rounded-md text-slate-900 text-xs font-semibold py-0.5 px-2 mb-2 whitespace-nowrap"
                      style={{
                        top: "-.7rem",
                        left: "50%",
                        transform: "translateX(-50%)",
                      }}
                    >
                      Most popular
                    </p>
                    <button
                      onClick={() =>
                        setSelectedAmount({ amount: 6.99, balance: 200 / 6 })
                      }
                      className={`${
                        selectedAmount.amount === 6.99
                          ? "border-white border bg-slate-600 shadow-md shadow-white"
                          : "bg-slate-800"
                      } border-solid border-2 border-yellow-500 flex justify-center items-center text-white font-semibold  hover:bg-slate-700 ease-in-out round shadow-sm shadow-white w-40`}
                    >
                      <p className="mr-2">💎 200</p>

                      <p className="text-slate-400">$6.99</p>
                    </button>
                  </div>
                  <button
                    onClick={() =>
                      setSelectedAmount({ amount: 49.99, balance: 1000 / 6 })
                    }
                    className={`${
                      selectedAmount.amount === 49.99
                        ? "border-white border bg-slate-600 shadow-md shadow-white"
                        : "bg-slate-800"
                    } flex justify-center items-center text-white font-semibold  hover:bg-slate-700 ease-in-out round shadow-sm shadow-white w-40`}
                  >
                    <p className="mr-2">💎 1000</p>

                    <p className="text-slate-400">$49.99</p>
                  </button>
                </div>

                <p className="text-slate-300 font-regular text-lg text-center mt-7 mb-9">
                  1 credit = 10 seconds
                </p>

                <div className="flex justify-center flex-wrap mb-10">
                  <button
                    onClick={confirmPurchase}
                    disabled={purchaseLoading}
                    className="flex justify-center items-center text-white font-semibold bg-blue-500 disabled:bg-blue-800 hover:bg-blue-600 ease-in-out round shadow-sm shadow-white ml-4"
                  >
                    {purchaseLoading ? "Processing..." : "Purchase credits"}
                  </button>

                  <button
                    onClick={() => setShowPurchaseModal(false)}
                    className="flex justify-center items-center text-white font-semibold bg-slate-800 hover:bg-slate-700 ease-in-out round shadow-sm shadow-white ml-4"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          )}
          {showCallModal && (
            <div
              className="w-full flex z-50 fixed h-full -top-4 left-0 bg-gray-500 bg-opacity-50"
              onClick={() => setShowCallModal(false)}
            >
              <div
                className="bg-gray-900 backdrop-filter backdrop-blur-lg bg-opacity-50 mx-auto w-500 flex my-auto py-12 md:h-1/3 md:w-1/4 w-11/12 h-1/3  px-12 rounded-sm shadow-4xl shadow-black flex-col relative"
                onClick={(e) => e.stopPropagation()}
              >
                <span
                  className="text-4xl text-white absolute top-0 left-4 text-opacity-50 cursor-pointer hover:text-opacity-80"
                  onClick={() => {
                    setShowCallModal(false);
                  }}
                >
                  x
                </span>
                <div className="flex flex-col my-auto space-y-4 w-40 mx-auto w-2/3">
                  {!loading && (
                    <div>
                      <h2 className="text-white font-sans bg-opacity-80 text-center space-y-2">
                        Talk to {mommyMap[selectedOption]}
                      </h2>
                    </div>
                  )}
                  {!loading && (
                    <div className="flex flex-col space-y-4">
                      <input
                        className="w-full bg-gray-900 border border-white text-white shadow-lg shadow-sm shadow-gray-900/80 "
                        type="text"
                        value={userPhoneNumber}
                        onChange={(e) => setUserPhoneNumber(e.target.value)}
                      />
                      <button
                        className=" bg-slate-800 border border-gray-800 shadow-sm shadow-white hover:shadow-none duration-200 border-solid rounded-md z-50"
                        onClick={handleCall}
                        disabled={loading}
                      >
                        Make Call
                      </button>
                      <button
                        className=" bg-red-800 text-xs border border-gray-800 shadow-sm mt-4 shadow-white hover:shadow-none duration-200 border-solid rounded-md z-50"
                        onClick={clearMemory}
                        disabled={loading}
                      >
                        Clear {mommyMap[selectedOption]}'s memory
                      </button>
                    </div>
                  )}
                  {loading && (
                    <div
                      className="w-full"
                      style={{
                        width: "50%",
                        marginLeft: "auto",
                        marginRight: "auto",
                        display: "flex",
                      }}
                    >
                      <div className="preloader">
                        <span></span>
                        <span></span>
                        <span></span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
