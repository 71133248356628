
const AboutPage = () => {
    return (
        <div className="h-screen mx-auto px-4 md:px-12 text-white py-12 overflow-y-scroll bg-gray-900">
            <div className="text-center mt-10">
                <h1 className="text-5xl font-bold mb-6">About LoveCall</h1>
                <p className="text-xl mb-4">
                    Welcome to LoveCall, the vanguard of AI-driven intimacy. Rooted in the heart of San Francisco, and propelled by a team of visionaries and venture capitalists, LoveCall is redefining the essence of digital companionship. Our platform is more than just an innovation; it's a bridge between technology and emotion, crafted to enhance your life with unparalleled experiences.
                </p>
                <p className="text-xl">
                    We understand the transformative power of connection and the importance of discretion. That's why LoveCall is committed to creating a safe, inclusive, and private environment for every user.
                </p>
            </div>
            <div className="my-12">
                <h2 className="text-4xl font-semibold mb-4">Our Mission</h2>
                <p className="text-xl mb-4">
                    LoveCall's mission extends beyond the boundaries of conventional digital interaction. We are dedicated to offering a unique, immersive, and personalized experience, harnessing the power of advanced AI technology. Our approach merges this cutting-edge technology with a profound understanding of human emotions, crafting interactions that are not only private and secure but also deeply meaningful and empathetic.
                </p>
                <p className="text-xl">
                    Our commitment to excellence in digital companionship drives us to constantly innovate and improve, ensuring our users always receive the best experience possible.
                </p>
            </div>
            <div className="my-12">
                <h2 className="text-4xl font-semibold mb-4">Security and Privacy</h2>
                <p className="text-xl mb-4">
                    At LoveCall, we understand that trust is the cornerstone of any meaningful interaction. That's why we've implemented an advanced encryption protocol, "SecureHeart," which utilizes end-to-end encryption to safeguard your conversations. Our system ensures that all personal information and communication are encrypted at rest, making your privacy our top priority.
                </p>
                <p className="text-xl mb-4">
                    Our privacy protocols are rigorously tested and regularly updated, incorporating the latest advancements in cybersecurity. This relentless pursuit of security excellence means that you can engage with our platform with the utmost confidence, knowing your data is protected with industry-leading technology.
                </p>
                <p className="text-xl">
                    Your peace of mind is paramount. We continuously work to enhance our security measures, ensuring they meet and exceed the highest standards of data protection.
                </p>
            </div>
            <div className="my-12">
                <h2 className="text-4xl font-semibold mb-4">Innovation at Its Core</h2>
                <p className="text-xl mb-4">
                    Innovation is the heartbeat of LoveCall. Our platform is built on a foundation of cutting-edge AI technology, designed not just to interact but to understand and empathize. This continuous evolution in our AI capabilities allows us to provide an experience that grows increasingly realistic and emotionally intelligent over time.
                </p>
                <p className="text-xl">
                    Our team of engineers and humans work tirelessly to ensure that every update, every improvement, brings us closer to a seamless integration of AI and human-like understanding, setting new benchmarks in the field of AI companionship.
                </p>
            </div>
        </div>
    )
}

export default AboutPage;
