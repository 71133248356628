import {
    PaymentElement,
    LinkAuthenticationElement
} from '@stripe/react-stripe-js'
import { useState } from 'react'
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { PiSpinnerGapLight } from "react-icons/pi"
import payment_bg from "../../assets/payment_bg.jpg"
export default function CheckoutForm({ pricePackage }) {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    console.log(pricePackage)
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: `${window.location.origin}/home`,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === "card_error" || error.type === "validation_error") {
            setMessage(error.message);
        } else {
            setMessage("An unexpected error occured.");
        }

        setIsLoading(false);
    }

    return (
        <form className="w-[95%] md:w-1/2 relative px-2  py-4 mx-auto h-full flex flex-col text-white bg-white rounded-sm" onSubmit={handleSubmit}>

            <LinkAuthenticationElement id="link-authentication-element"
            // Access the email value like so:
            // onChange={(event) => {
            //  setEmail(event.value.email);
            // }}
            //
            // Prefill the email field like so:
            // options={{defaultValues: {email: 'foo@bar.com'}}}
            />
            <PaymentElement className="bg-white z-10 mt-4 flex flex-col text-white bg-white px-4 appearance-none border rounded w-full  py-4  leading-tight focus:outline-none " />
            <button disabled={isLoading || !stripe || !elements} id="submit" className="mt-12 border border-gray-500 bg-gray-800">
                <button className="w-full bg-white text-black rounded-sm border-2 border-gray-900 shadow-sm">
                    {isLoading ? <PiSpinnerGapLight className=" animate-spin w-full rounded-full w-5 h-5 rounded-lg" /> : <div>Pay Now</div>}

                </button>
            </button>
            {/* Show any error or success messages */}
            {message && <div className="my-8 " id="payment-message">{message}</div>}
            <div className="text-center text-xs italic py-4 mt-4 text-black">Your payment information is not stored anywhere, all transactions and user data is processed by Stripe. Invoices will show up from a non descript name.</div>
        </form >
    )
}