import React, { useState, useEffect } from "react";
import "./css/chat.css"; // Ensure this CSS file is linked
import { DeepChat } from "deep-chat-react";
import findom from "../pages/findom.png";

function ChatPage() {
  const [tipAmount, setTipAmount] = useState("");
  const [ageConfirmed, setAgeConfirmed] = useState(false);
  useEffect(() => {
    const savedAge = localStorage.getItem("age");
    if (savedAge) {
      setAgeConfirmed(parseInt(savedAge, 10) >= 18);
    } else {
      const age = prompt("Please enter your age:");
      if (parseInt(age, 10) >= 18) {
        localStorage.setItem("age", age);
        setAgeConfirmed(true);
      } else {
        alert("You must be 18 or older to use this chat.");
        setAgeConfirmed(false);
      }
    }
  }, []);
  document.body.style.overflow = "hidden";

  const handleSendTip = () => {
    console.log("Tip sent:", tipAmount);
    // Insert logic for handling the tip transaction
    setTipAmount("");
  };

  const chatElementRef = React.useRef(null);
  let currentIndex = 0;

  if (!ageConfirmed) {
    return <div>Please confirm your age to use the chat.</div>;
  }

  return (
    <div>
      <DeepChat
        id="chat-element"
        style={{
          borderRadius: "10px",
          width: "100vw",
          height: "100vh",
          paddingTop: "10px",
          backgroundColor: "#030814", // Dark theme background color
        }}
        requestBodyLimits={{
          maxMessages: 100,
        }}
        maxMessages={100}
        introMessage={""}
        textInput={{
          styles: {
            
          },
          placeholder: { text: "Type here...." },
        }}
        messageStyles={{
          default: {
            shared: {
              innerContainer: {
                fontSize: "2rem",
                backgroundColor: "#030814", // Dark theme background color
              },
            },
          },
        }}

        inputAreaStyle={{
          fontSize: "2rem",
          backgroundColor: "#030814", // Dark theme background color
        }}
        connect={{ url: "/api/openai/chat/route", stream: false }}
      ></DeepChat>
    </div>
  );
}

export default ChatPage;
