import { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm.js'
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';

function Payment(props) {
    const [pricePackage, setPackage] = useState(0)
    const [showCheckoutForm, setShowCheckoutForm] = useState(false)
    const stripePromise = loadStripe('pk_live_51ODuJ3INoBG4tl95umKl3a7TqQEsn8IgKG7K7x8XZFUJHC0VUoWmYORjsfvzdZlKhgoXc5WffWdICJqLxUnfhxzD00qaenTnfm');
    const options = [
        {
            name: 'Base',
            id: 0,
            price: '3.99',
            description: 'Get '
        }
    ]
    const [clientSecret, setClientSecret] = useState('');

    // useEffect(() => {
    //     getClientSecret()
    // }, [showCheckoutForm])
    const getClientSecret = async () => {
        const token = localStorage.getItem('auth');
        const billingDetails = {
            email: "test@",
        };
        const headers = {
            'authorization': `${token}`,
        };
        const { data: clientSecret } = await axios.post('/api/billing/pay_one_time', { email: billingDetails.email, card: '', package: pricePackage }, { headers });
        console.log(clientSecret)
        setClientSecret(clientSecret?.clientSecret)
    }
    return (
        <>
            {!showCheckoutForm && <div className="fixed w-full h-screen bg-slate-900 flex top-0 left-0">
                <div className="mx-auto my-auto flex flex-col bg-slate-900 rounded-sm px-4 py-4 md:w-1/3">
                    <h2 className="text-center text-white text-lg font-bold mb-8 mt-3">Add credits and keep chatting</h2>

                    {/* Replace these with your actual amounts and styling */}
                    <div className="flex justify-center flex-wrap gap-4">

                        <button onClick={() => setPackage({ amount: 3.99, balance: 50 / 6 })} className={`${pricePackage.amount === 3.99 ? '  border-4 border-red-500 bg-blue-800 ' : ' bg-slate-800'} flex justify-center items-center text-white font-semibold ease-in-out round shadow-sm shadow-white w-40`}>
                            <p className="mr-2">
                                💎 50
                            </p>

                            <p className="text-slate-400">
                                $3.99
                            </p>
                        </button>

                        <div className="relative">

                            <p className="absolute text-center bg-yellow-500 rounded-md text-slate-900 text-xs font-semibold py-0.5 px-2 mb-2 whitespace-nowrap" style={{ top: '-.7rem', left: '50%', transform: 'translateX(-50%)' }}>
                                Most popular
                            </p>
                            <button onClick={() => setPackage({ amount: 6.99, balance: 200 / 6 })} className={`${pricePackage.amount === 6.99 ? ' border border-red-500 bg-red-800' : 'bg-slate-800'} border-solid border-2 border-yellow-500 flex justify-center items-center text-white font-semibold   ease-in-out round shadow-sm shadow-white w-40`}>
                                <p className="mr-2">
                                    💎 200
                                </p>

                                <p className="text-slate-400">
                                    $6.99
                                </p>
                            </button>
                        </div>
                        <button onClick={() => setPackage({ amount: 49.99, balance: 1000 / 6 })} className={`${pricePackage.amount === 49.99 ? ' border border-red-500 bg-blue-800' : 'bg-slate-800'} flex justify-center items-center text-white font-semibold   ease-in-out round shadow-sm shadow-white w-40`}>
                            <p className="mr-2">
                                💎 1000
                            </p>

                            <p className="text-slate-400">
                                $49.99
                            </p>
                        </button>
                    </div>


                    <p className="text-slate-300 font-regular text-lg text-center mt-7 mb-9">
                        1 credit = 10 seconds
                    </p>

                    <div className="flex justify-center flex-wrap mb-10">
                        <button onClick={() => { setShowCheckoutForm(true); getClientSecret() }} className="flex justify-center items-center text-white font-semibold bg-blue-500 disabled:bg-blue-800 hover:bg-blue-600 ease-in-out round shadow-sm shadow-white ml-4">
                            Next
                        </button>


                    </div>
                </div>
                <div className="text-white absolute bottom-4 w-full text-center font-bold">Your purchases are always private.</div>
            </div>}
            {showCheckoutForm && <> <h1 className="text-center text-white text-xl py-4 font-sans"> {pricePackage?.amount} Payment</h1>
                {clientSecret && stripePromise && (
                    <Elements stripe={stripePromise} options={{ clientSecret, pricePackage }}>
                        <CheckoutForm pricePackage={pricePackage} />
                    </Elements>
                )} </>}

        </>
    );
}

export default Payment;