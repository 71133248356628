import { MagnifyingGlassIcon } from "@radix-ui/react-icons"
import axios from "axios"
import { jwtDecode } from "jwt-decode";

import { ArrowBigUpIcon, ArrowUpCircle, PhoneCallIcon } from "lucide-react"
import { useEffect, useState, useRef } from "react"
import { PiSpinner } from "react-icons/pi";
import { IoIosAdd, IoMdArrowBack } from "react-icons/io";

const HomePage = () => {
    const [selectedConversation, setSelectedConversation] = useState(null)
    const [messageMapId, setMessageMapId] = useState(null)
    const [messages, setMessages] = useState([])
    const [conversations, setConversations] = useState([])
    const [newMessage, setNewMessage] = useState(""); // State to manage the new message input
    const [userId, setUserId] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showPersonas, setShowPersonas] = useState(false)
    const messagesEndRef = useRef(null);
    const [personas, setPersonas] = useState([])
    const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };
    const handleResize = () => {
        setIsMobileView(window.innerWidth < 768);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    useEffect(() => {
        const token = localStorage.getItem('auth');
        if (token) {
            try {
                const decoded = jwtDecode(token);
                setUserId(decoded.uuid);
            } catch (error) {
                console.error("Failed to decode token", error);
            }
        }
    }, []);

    useEffect(() => {
        getConversations();
    }, []);

    useEffect(() => {
        if (selectedConversation) {
            getMessages(selectedConversation.conversation_id);
        }
    }, [selectedConversation]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);



    useEffect(() => {
        const token = localStorage.getItem('auth');
        if (token) {
            try {
                const decoded = jwtDecode(token);
                setUserId(decoded.uuid); // Assuming the user's ID is stored in the 'id' field
            } catch (error) {
                console.error("Failed to decode token", error);
            }
        }
    }, []);
    // ... existing code ...
    const createConversation = async (aiPersonaId) => {

        const token = localStorage.getItem('auth'); // Retrieve the auth token
        const apiUrl = '/api/conversations/create'; // Replace with your actual API endpoint

        try {
            const response = await axios.post(apiUrl, { aiPersonaId }, {
                headers: {
                    'Authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            console.log("Conversation created:", response.data);
            getConversations()
            return response.data; // Return the created conversation data
        } catch (error) {
            console.error("Error creating conversation:", error);
            throw error; // Rethrow the error for further handling
        }
    };
    const sendMessage = async () => {
        const token = localStorage.getItem('auth');
        const fromUserId = userId;
        const toUserId = selectedConversation?.ai_id;

        if (!newMessage.trim()) return;
        setIsLoading(true);

        try {
            const sentMessage = {
                conversationId: selectedConversation?.conversation_id,
                from: fromUserId,
                to: toUserId,
                text: newMessage,
                isImage: false
            };
            setMessages([...messages, sentMessage]); // Instantly append the message
            setNewMessage("");
            await axios.post('/api/messages/send', sentMessage, { headers: { 'authorization': token } });


        } catch (error) {
            console.log(error);
        } finally {
            getMessages(selectedConversation?.conversation_id)
            setIsLoading(false);
            scrollToBottom();
        }
    };

    const getConversations = async () => {

        const token = localStorage.getItem('auth');

        try {
            const res = await axios.post('/api/conversations/get', {}, { headers: { 'authorization': token } });
            console.log(res)
            setConversations(await res?.data?.conversationsData)
            console.log(conversations)
            // return res?.data?.conversationsData[0]?.conversation_id
            // Handle successful call initiation here
        } catch (error) {
            console.log(error)
            // Handle error here
        }
    }
    const getMessages = async (id) => {
        console.log(id)
        const token = localStorage.getItem('auth');

        try {
            const res = await axios.post('/api/messages/get', { conversationId: id }, { headers: { 'authorization': token } });
            console.log(res)
            setMessages(res.data?.messages)

        } catch (error) {
            console.log(error)
            // Handle error here
        }
    }

    const getAiPersonas = async () => {
        const token = localStorage.getItem('auth');

        try {
            const res = await axios.post('/api/personas/all', {}, { headers: { 'authorization': token } });
            console.log(res);

            // Filter out personas that are already in existing conversations
            const existingPersonaIds = new Set(conversations.map(convo => convo.ai_id));
            const availablePersonas = res.data.filter(persona => !existingPersonaIds.has(persona.uuid));

            setPersonas(availablePersonas);
        } catch (error) {
            console.log(error);
            // Handle error here
        }
    };




    useEffect(() => {
        getConversations().then((id) => { })
    }, [])
    useEffect(() => {
        console.log(selectedConversation)
        getMessages(selectedConversation?.conversation_id)
    }, [selectedConversation])
    const yourUserIdHere = "1"
    // const conversations = [
    //     {
    //         name: "Alexa",
    //         last_message: "Hi there big boi",
    //         is_read: true,
    //         conversation_id: 0
    //     },
    //     {
    //         name: "Sammy",
    //         last_message: "Hi there big boi",
    //         is_read: false,
    //         conversation_id: 1
    //     }
    // ]
    const sampleMessages = [
        {
            id: 0,
            messages: [
                {
                    from: "sample-user-id",
                    to: "1",
                    text: "You wanna get rough"
                },
                {
                    from: "sample-user-id",
                    to: "1",
                    text: "Hey big boy hows it going"
                },
                {
                    from: "1",
                    to: "sample-user-id",
                    text: "Fuck you"
                },
            ]
        },
        {
            id: 1,
            messages: [
                {
                    from: "sample-user-id",
                    to: "1",
                    text: "I like Trains"
                },
                {
                    from: "sample-user-id",
                    to: "1",
                    text: "I like cows"
                },
                {
                    from: "1",
                    to: "sample-user-id",
                    text: "You r cool"
                },
                {
                    from: "1",
                    to: "sample-user-id",
                    text: "You are lame"
                },
            ]
        }
    ]

    return (
        <div className="bg-gray-50 w-full h-screen flex">
            <div className={`md:w-1/4 w-full bg-gray-200 border-r border-gray-500 shadow-xl shadow-gray-400 px-4 py-6 ${isMobileView && selectedConversation ? 'hidden' : ''}`}>

                <div className=" w-full text-left text-sm py-4 flex justify-between relative">
                    <div>Conversations</div>
                    <IoIosAdd className=" text-blue-400 text-lg hover:text-xl cursor-pointer z-[100] border-2 border-blue-500 rounded-full" onClick={async () => { setShowPersonas(true); getAiPersonas(); }} />
                    <div >
                    </div>

                    {showPersonas && <div className="absolute top-8 left-48  border border-gray-300 z-[50] rounded-md shadow-md bg-white py-2 px-2 w-full" onMouseLeave={() => { setShowPersonas(false) }}>
                        <div>New Conversation.</div>
                        {personas?.map((key, idx) => {
                            console.log(key)
                            return (
                                <div className="flex flex-col border-b border-gray-200 py-1 hover:bg-blue-400 px-2  rounded-md cursor-pointer " onClick={() => { createConversation(key?.uuid) }}>

                                    <div className="flex space-x-2">
                                        <div className="w-8 h-8 rounded-full bg-red-200 flex">
                                            <span className="mx-auto my-auto">{key?.name.charAt(0)}</span>
                                        </div>
                                        <div className="my-auto">{key?.name}</div>
                                    </div>
                                    <div className="text-xs">Start a new conversation with {key?.name}</div>
                                </div>
                            )
                        })
                        }

                    </div>}

                </div>
                <div className=" w-full bg-gray-300 rounded-md text-left text-sm py-2 px-4 mb-4 space-x-2 cursor-text flex">
                    <MagnifyingGlassIcon className="my-auto" /> <span className="my-auto">Search</span>

                </div>
                {
                    conversations?.map((key, idx) => {
                        return (
                            <div onClick={() => { setSelectedConversation(key); setMessageMapId(key?.conversation_id) }} className={`flex relative border-b border-gray-300 px-5 py-2 cursor-pointer ${selectedConversation === key?.conversation_id && 'bg-blue-500'} rounded-sm py-2 my-1  hover:bg-blue-500 duration-200 `}>
                                {key?.is_read && <div className=" absolute left-1 top-0 h-full flex">
                                    <span className="w-2 h-2 rounded-full bg-blue-500 my-auto "></span>
                                </div>}
                                <div className="w-1/4">
                                    <div className="w-12 h-12 rounded-full bg-pink-200 flex ">
                                        <div className="mx-auto my-auto">{key?.persona?.name.charAt(0)} </div>
                                    </div>
                                </div>
                                <div className="w-3/4 flex flex-col">
                                    <div className="font-semibold">{key?.persona?.name}</div>
                                    <div className="text-xs">{key?.last_message}</div>
                                </div>

                            </div>
                        )
                    })
                }

            </div>
            <div className={`md:w-3/4 w-full bg-white pt-[4vh] relative ${isMobileView && !selectedConversation ? 'hidden' : ''}`}>
                {isMobileView && <div className="w-full relative top-0">
                    <IoMdArrowBack className="absolute -top-[3vh] rounded-full left-4 bg-white text-2xl text-blue-400 " onClick={() => { setSelectedConversation(null) }} />
                    <PhoneCallIcon className="absolute -top-[3vh] rounded-md right-4 bg-white text-2xl text-blue-400 " />
                </div>}
                <div className="w-full h-[86vh] overflow-y-scroll">


                    {
                        messages && messages?.map((key, idx) => {
                            return (
                                <div className="flex flex-col text-black w-full">

                                    <div className="flex md:w-5/6 w-11/12 mx-auto my-1 md:text-md text-sm font-light">
                                        <div className={`${key?.from === userId ? '  ml-auto  bg-blue-300 rounded-t-full rounded-bl-full md:pl-4 pl-4 pr-3 ' : ' mr-auto bg-gray-300 pl-4 rounded-t-full rounded-br-full pr-3 md:pl-4'}  py-3 `}>
                                            {key?.text}
                                        </div>

                                    </div>




                                </div>
                            )
                        })
                    }
                    <div ref={messagesEndRef} />
                </div>
                <div className=" w-full mx-auto bg-white absolute bottom-0 md: h-[8vh] h-[10vh] left-0 right-0 flex backdrop-blur bg-blur-xl bg-opacity-90">
                    <div className="mx-auto my-auto w-4/5 md:w-2/3 bg-gray-100 rounded-full flex md:text-sm">
                        <input
                            placeholder="Message"
                            className="w-full bg-opacity-0 bg-white border-none outline-none"
                            value={newMessage}
                            onChange={(e) => setNewMessage(e.target.value)}
                            disabled={isLoading}
                        />
                        {isLoading ? (
                            <PiSpinner className=" animate-spin my-auto mr-2" /> // Replace with your loading spinner
                        ) : (
                            <ArrowUpCircle
                                className="my-auto mr-2 text-blue-500"
                                onClick={sendMessage}
                            />
                        )}</div>
                </div>
            </div>

        </div>
    )
}
export default HomePage;